<template>
  <div class="active-dark bg_color--6">

    <HeaderOnePageTwo />

    <div class="slider-wrapper poss_relative" id="uvod">
      <div
        class="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-center bg_image home-bg-dark"
      >
        <v-container>
          <v-row style="align-items: center;">
            <v-col cols="12">
              <div class="text-center inner">
                <h1 class="heading-titl">
                  Vyškov
                </h1>
                <h2>
                  Doplatek na školení (12. – 15.10.2023)
                </h2>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <div class="rn-contact-area pt--120 pb--200 mb--50 bg_color--8" id="registrace">
      <div class="contact-form--1">
        <v-container>
          <Registrace>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-13.jpg"
              alt="contact images"
            />
          </Registrace>
        </v-container>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo2";
  import Registrace from "../../components/contact/ContactVyskovDoplatek";
  import Footer from "../../components/footer/FooterThree";

  export default {
    components: {
      HeaderOnePageTwo,
      Registrace,
      Footer,
    },
    data() {
      return {}
    },
  };
</script>

<style>
.rotate-1{
  transform: rotate(-1deg);
}
.btn-cta-top{
  background: var(--color-secondary)!important;
  border-color: var(--color-secondary)!important;
  color: white!important;
}
.btn-cta-top:hover{
  background: transparent!important;
}
</style>
