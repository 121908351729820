import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
import Demo from '../views/all-home-version/LandingDarkPortfolio.vue'
import Vyskov from '../views/all-home-version/VyskovReg.vue'
import VyskovBox from '../views/all-home-version/VyskovBoxReg.vue'
import Doplatek from '../views/all-home-version/VyskovDoplatek.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Demo,
        meta: {
            title: 'PískejHokej.cz'
        }
    },
    {
        path: '/vyskov',
        component: Vyskov,
        meta: {
            title: 'Vyškov | PískejHokej.cz'
        }
    },
    {
        path: '/vyskovbox',
        component: VyskovBox,
        meta: {
            title: 'Vyškov – Box | PískejHokej.cz'
        }
    },
    {
        path: '/doplatek',
        component: Doplatek,
        meta: {
            title: 'Vyškov – Doplatek | PískejHokej.cz'
        }
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: '404 | PískejHokej.cz'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router