<template>
  <div class="active-dark bg_color--6">

    <HeaderOnePageTwo />

    <div class="slider-wrapper poss_relative" id="uvod">
      <div
        class="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-center bg_image home-bg-dark"
      >
        <v-container>
          <v-row style="align-items: center;">
<!--            <v-snackbar-->
<!--                :timeout="-1"-->
<!--                :value="true"-->
<!--                absolute-->
<!--                top-->
<!--                color="#D3002C"-->
<!--                elevation="24"-->
<!--                rounded-->
<!--                class="mt&#45;&#45;120 text-center"-->
<!--            >-->
<!--              <b>Pro úspěšné absolventy kompletního školení 2023:</b><br>Rozhodcovský dres, píšťalka a malý dárek!-->
<!--            </v-snackbar>-->

            <v-col lg="6" cols="12">
              <div class="text-md-left text-center inner">
                <h1 class="heading-title">
                  ZÁŽITEK. EMOCE. <br />
                  ZODPOVĚDNOST.
                </h1>
                <h2>
                  HOKEJ POTŘEBUJE I ŽENSKÉ ROZHODČÍ
                </h2>
                <!-- <span style="color: #FFFFFF;  font-weight: bold;">14.-17.října 2021, Vyškov</span> -->
                <div class="slide-btn mt--30">
                  <scrollactive active-class="v-btn--active" bezier-easing-value=".5,0,.35,1" :offset="71">
                    <a class="btn-default btn-opacity scrollactive-item btn-cta-top" href="#registrace-a-kontakt">STAŇ SE HOKEJOVÝM ROZHODČÍM</a>
                  </scrollactive>
                </div>

<!--                 <span style="color: white; font-weight: bold; margin-top: 40px; font-size: 18px;">říjen 2021</span>-->
              </div>
            </v-col>
            <v-col lg="6" cols="12" class="d-none d-lg-flex" style="display: flex; justify-content: flex-end;">
              <img style="max-width: 66%; display: flex;" src="@/assets/images/hokej/whistle.svg" />
            </v-col>

          </v-row>
        </v-container>
      </div>
    </div>

    <v-dialog v-model="dialog" width="768px" @input="videoClosed" style="overflow: visible;">
        <div style="padding:56.25% 0 0 0;position:relative;">
          <v-fab-transition>
            <v-btn
                color="primary"
                fab
                dark
                small
                absolute
                top
                right
                style="top: 15px;"
                @click="dialog = false; videoClosed()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-fab-transition>
          <iframe id="autoplayVideo" src="https://player.vimeo.com/video/911892159?autoplay=1&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Rozhodčí"></iframe>
        </div>

    </v-dialog>

    <div class="about-area pt--120 bg_color--8">
      <div class="about-wrapper">
        <div class="container">
          <Hokej>
            <img
              slot="thum-img"
              class="w-100"
              src="../../assets/images/hokej/uvod.png"
              alt="About Images"
            />
          </Hokej>
        </div>
      </div>
    </div>

    <div class="service-area creative-service-wrapper pt--120 bg_color--8">
      <div class="container" id="reference">
        <v-row>
          <v-col lg="12">
            <div class="text-md-center text-left section-title mb--30 mb_sm--0">
              <h2 class="heading-title">Buď jedním z nás!</h2>
              <p>
                Všichni rozhodčí začínali stejně. Školení rozhodčích III. třídy je pouze začátek.<br>
                Záleží jen na tobě, jak na sobě budeš pracovat. Za pár let pak můžeš pískat<br>
                ty nejvyšší české soutěže, případně mezinárodní zápasy.
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="rn-testimonial-area">
          <v-container>
            <Reference />
          </v-container>
        </div>
      </div>
    </div>

    <div class="rn-blog-area pt--120 bg_color--8" id="proc-se-stat-rozhodcim">
      <v-container>
        <v-row align="end" class="mb--20">
          <v-col md="12">
            <div class="text-md-center text-left section-title">
              <h2 class="heading-title">Proč se stát rozhodčím?</h2>
              <p>
                Zažívej s námi každý týden jen ty největší emoce - pozitivní i negativní,<br>
                které hokej neustále přináší! Stát se rozhodčím znamená skvělou příležitost,<br>
                jak být důležitou součástí hokeje, být do něj vtažený a prožívat ho naplno!
              </p>
            </div>
          </v-col>
        </v-row>
        <Proc />
      </v-container>
    </div>

    <div class="rn-blog-area pt--120 bg_color--8" id="podminky-ziskani-licence">
      <v-container>
        <Podminky />
      </v-container>
    </div>

    <div class="rn-blog-area pt--120 bg_color--8" id="terminy-skoleni">
      <v-container>
        <Terminy />
      </v-container>
    </div>

    <div class="portfolio-area pt--120 bg_color--8" id="program-skoleni">
      <v-container>
        <Skoleni />
      </v-container>
    </div>

    <div class="rn-contact-area pt--120 pb--120 mb--50 bg_color--8" id="registrace-a-kontakt">
      <div class="contact-form--1">
        <v-container>
          <Registrace>
            <img
              slot="contact-img"
              class="w-100"
              src="../../assets/images/about/about-13.jpg"
              alt="contact images"
            />
          </Registrace>
        </v-container>
      </div>
    </div>

<!--    <div class="rn-contact-area pt&#45;&#45;60 pb&#45;&#45;120 mb&#45;&#45;50 bg_color&#45;&#45;8" id="registrace-a-kontakt">-->
<!--        <v-container class="text-center rounded-4">-->
<!--          <a href="https://reeq.store/" target="_blank">-->
<!--            <img-->
<!--                slot="contact-img"-->
<!--                src="../../assets/images/Reeq.png"-->
<!--                alt="contact images"-->
<!--            />-->
<!--          </a>-->
<!--        </v-container>-->
<!--    </div>-->

    <Footer />
  </div>
</template>

<script>
  import HeaderOnePageTwo from "../../components/header/HeaderOnePageTwo";
  import Hokej from "../../components/about/AboutFour";
  import Reference from "../../components/testimonial/Testimonial";
  import Skoleni from "../../components/about/AboutThree";
  import Proc from "../../components/accordion/Accordion2";
  import Podminky from "../../components/about/AboutFive";
  import Terminy from "../../components/about/TerminySkoleni";
  import Registrace from "../../components/contact/Contact";
  import Footer from "../../components/footer/FooterThree";

  export default {
    components: {
      HeaderOnePageTwo,
      Hokej,
      Reference,
      Skoleni,
      Proc,
      Podminky,
      Terminy,
      Registrace,
      Footer,
    },
    data() {
      return {
        dialog: false,
        player: null
      }
    },
    methods: {
      videoClosed() {
        const vid = new Vimeo.Player(document.getElementById('autoplayVideo'));
        vid.destroy()
      }
    }
  };
</script>

<style>
.rotate-1{
  transform: rotate(-1deg);
}
.btn-cta-top{
  background: var(--color-secondary)!important;
  border-color: var(--color-secondary)!important;
  color: white!important;
}
.btn-cta-top:hover{
  background: transparent!important;
}
</style>
