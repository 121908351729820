<template>
  <v-row align="start" class="row--35">
    <!-- <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1"> -->
    <v-col cols="12">
      <div class="text-left section-title mb--50">
        <h2 class="heading-title">Přihláška na školení rozhodčích ledního hokeje – Vyškov; 12.10.2024</h2>
        <div class="im_address">
          <span>Děkujeme Vám, že jste se rozhodli závazně přihlásit na školení rozhodčích ledního hokeje.
Prosím, postupujte v následujících krocích:</span>
        </div>
      </div>
      <div class="form-wrapper">
          <iframe name="iframeForm" id="iframeForm" style="display: none" />
          <!-- <form id="kontaktForm" target="iframeForm" action="https://marketing.construsoft.com/acton/eform/36418/a2083420-a367-4454-812c-d26b616250f8/d-ext-0001"> -->
          <!-- <form id="kontaktForm" target="iframeForm" action="https://marketing.construsoft.com/acton/eform/36418/b801a318-6f02-4572-9954-e0c4ebb61476/d-ext-0001"> -->
          <form id="kontaktForm" target="iframeForm" action="https://marketing.construsoft.com/acton/eform/36418/a2083420-a367-4454-812c-d26b616250f8/d-ext-0001">
            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 1:</b> Osobní údaje</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="jmeno" id="jmeno" type="text" v-model="formData.jmeno" placeholder="Jméno a příjmení *"/>
                  </label>
              </v-col>
              <v-col lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="email" id="email" type="email" v-model="formData.email" placeholder="E-mail *"/>
                  </label>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="mesto" id="mesto" type="text" v-model="formData.telefon" placeholder="Telefonní číslo *"/>
                  </label>
              </v-col>
              <v-col lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="tel" id="tel" type="text" v-model="formData.rc" placeholder="Rodné číslo *"/>
                  </label>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <label>
                    <input name="mesto" id="mesto" type="text" v-model="formData.adresa" placeholder="Adresa trvalého bydliště *"/>
                  </label>
              </v-col>
            </v-row>

            <v-divider class="d-none d-lg-flex" dark></v-divider>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 2:</b> Proveďte platbu</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;">Platbu za školení pomocných rozhodčích ve výši 1050,-Kč uhraďte bankovním převodem na účet číslo <b>8577469002/5500</b> pod variabilním symbolem 20XXXXXX22, kde XXXXXX je 6 čísel z rodného čísla před lomítkem. Do poznámky pro příjemce prosím ještě uveďte Vaše jméno a příjmení.</p>
              </v-col>
            </v-row>

            <v-divider class="d-none d-lg-flex" dark></v-divider>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 3:</b> Prosím připojte (nahrajte) jakékoli potvrzení o provedené platbě (například: .pdf potvrzení, foto, printscreen - snímek z mobilu apod.)</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <v-file-input label="Vyberte soubor kliknutím" dark v-model="formData.soubor"></v-file-input>
              </v-col>
            </v-row>

            <v-divider class="d-none d-lg-flex" dark></v-divider>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 4: </b>Prosím, potvrďte následující</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col cols="12">
                  <v-checkbox hide-details label="Závazně se přihlašuji na základní školení pomocných rozhodčích (Vyškov, 12.10.2024). Potvrzuji, že uvedené údaje jsou úplné a pravdivé. Souhlasím s použitím výše uvedených údajů pro evidenci v rámci ČSLH. Případnou změnu údajů uvedených v přihlášce nahlásím neprodleně na e-mail jihomoravsky@czehockey.cz" dark v-model="formData.souhlas1"></v-checkbox>
              </v-col>

              <v-col cols="12">
                  <v-checkbox hide-details label="Potvrzuji, že jsem provedl úhradu platby za školení rozhodčích a že zaplacená částka v případě mé neúčasti propadá pořadateli školení." dark v-model="formData.souhlas2"></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                <v-btn :loading="loading" :color="!success ? 'primary' : 'secondary'" @click.prevent="onSubmit()" :style="success ? 'pointer-events: none; background: #FFBE00; border-color: #FFBE00' : ''">{{ success ? 'Odesláno' : 'Závazně se přihlásit' }}</v-btn>
                <!-- <button class="btn-default" @click.prevent="onSubmit()" loading v-html="success ? 'Odesláno' : 'Závazně se zaregistrovat'" :style="success ? 'pointer-events: none; background: #FFBE00; border-color: #FFBE00' : ''"></button> -->
              </v-col>
            </v-row>
          </form>

          <p v-if="error" :style="`color: ${success ? 'green' : 'red'};`">{{error}}</p>
      </div>
    </v-col>

  </v-row>
</template>

<script>
  import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
  import {addDoc, collection} from "firebase/firestore";
  import {db} from "@/firebase";

  export default {
    data() {
      return {
        formData: {
          jmeno: "",
          email: "",
          telefon: "",
          adresa: "",
          rc: "",
          soubor: null,
          souhlas1: false, souhlas2: false, souhlas3: 'N/A'
        },
        loading: false,
        error: null,
        success: false,
      };
    },
    methods: {
      async onSubmit() {
        if (this.formData.souhlas1 && this.formData.souhlas2 && this.formData.souhlas3 && this.formData.jmeno && this.formData.email && this.formData.telefon && this.formData.adresa && this.formData.soubor) {
          this.loading = true

          const storage = getStorage()
          const storageRef = ref(storage, `vyskov2024/box/${this.formData.jmeno} - ${new Date().toLocaleString().replaceAll('/', '.')} - ${this.formData.soubor.name.replaceAll('/', '_')}`)
          uploadBytes(storageRef, this.formData.soubor)
            .then((snapshot) => {
              getDownloadURL(snapshot.ref)
                .then(async (url) => {
                  const docRef = collection(db, 'Vyskov2024')
                  await addDoc(docRef, {
                    fullName: this.formData.jmeno,
                    email: this.formData.email,
                    phone: this.formData.telefon,
                    rc: this.formData.rc,
                    address: this.formData.adresa,
                    file: url,
                    size: '–',
                    date: new Date(),
                    type: 'Pomocný rozhodčí'
                  })

                  this.success = true
                  this.loading = false
                  this.error = 'Přihlášení proběhlo v pořádku, děkujeme!'
                })
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          this.error = "Vyplňte prosím všechna povinná pole";
        }
      },

      post(path, params, method='post') {
        const form = document.createElement('form')
        form.method = method
        form.action = path
        form.target = 'iframeForm'

        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input')
            hiddenField.type = 'hidden'
            hiddenField.name = key
            hiddenField.value = params[key]

            form.appendChild(hiddenField)
          }
        }

        document.body.appendChild(form)
        form.submit()
      }
    }
  };
</script>

<style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
  .inpur-error{
    color: white;
  }
  .link-kontakt{
    color: white!important;
  }
  .link-kontakt:hover{
    color: var(--color-secondary)!important;
  }
  .v-input__slot {
    padding: 5px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: .4rem!important;
  }
  .v-select__selection--comma, .v-select__selection {
    color: rgba(198, 201, 216, 0.75);
    padding: 0 10px;
  }
  #kraj, #forma {
    padding: 0 7px;
  }
  .v-select .v-input__slot {
    min-height: 46px!important;
  }
  .v-select__slot .v-input__append-inner{
    margin-top: 13px!important;
  }
  input#kraj, input#forma{
    border: none;
  }
  input#kraj::placeholder,  input#forma::placeholder{
    color: #757575;
  }
  .v-autocomplete{
    padding: 0!important;
  }
  .v-icon.notranslate.mdi.mdi-menu-down.theme--light{
    color: #D3002C!important;
  }

  .v-input__slot, .mdi-paperclip {
    border: none!important;
  }
</style>
