<template>
  <v-row class="row--35" align="center">
    <v-col lg="5" md="5" cols="12">
      <div class="thumbnail">
        <slot name="thum-img"></slot>
      </div>
    </v-col>
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner">
        
        <div class="section-title">
          <!-- <span class="subtitle">My About Details</span> -->
          <h2 class="heading-title">Baví tě lední hokej a chceš se stát jeho součástí? </h2>
          <p class="description mt_dec--20">
            Co takto se stát hokejovým rozhodčím a potkávat se se známými trenéry, hráči a legendami českého hokeje. Jsi chlapec nebo dívka? Nyní máš jedinečnou šanci rozšířit řady rozhodčích ledního hokeje a s každým zápasem prožívat nezapomenutelné zážitky. 
          </p>
          <p class="description">
            Můžeš si vybrat, zda máš zájem řídit utkání přímo na ledě nebo zda doplníš náš tým v boxu rozhodčích (zapisovatel, časoměřič, hlasatel a podobně). Získat budeš moci jednu z následujících tří licencí: licence III. třídy, licenci pro minihokej, licenci pro pomocné rozhodčí – box.
          </p>
          <!-- <p class="description">
            Pro každého účastníka, který absolvuje celé školení (III. třídy na led), vybavení dresem a píšťalkou ZDARMA!
          </p> -->
        </div>

      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>
