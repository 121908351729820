<template>
  <v-row class="row--35 align-center">
    <v-col md="7" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <h2 class="heading-title">Podmínky získání licence</h2>
          <p class="description">
            Získání licence má své stanovené podmínky, které musí každý zájemce splnit. Není se ale čeho bát. Na vše tě dokonale připravíme a po úspěšném absolvování semináře se ihned staneš hokejovým rozhodčím. 
          </p>
        </div>
        <v-row>
          <v-col md="12" sm="12" cols="12">
            <div class="about-us-list">
              <h5 class="heading-title"><v-icon dark left>mdi-arrow-right-bold</v-icon> Minimálně 15 let </h5>
              <h5 class="heading-title"><v-icon dark left>mdi-arrow-right-bold</v-icon> Účast na základním školením rozhodčích ledního hokeje </h5>
              <h5 class="heading-title"><v-icon dark left>mdi-arrow-right-bold</v-icon> Absolvování testu z teorie</h5>
              <h5 class="heading-title"><v-icon dark left>mdi-arrow-right-bold</v-icon> Absolvování testu z bruslení (nevztahuje se na rozhodčí v boxu) </h5>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col md="5" sm="12" cols="12">
      <div class="thumbnail">
        <img
          class="w-100"
          src="../../assets/images/hokej/licence.png"
          alt="About Images"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
