<template>
  <v-row align="start" class="row--35">
    <!-- <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1"> -->
    <v-col cols="12">
      <div class="text-left section-title mb--50">
        <h2 class="heading-title">Doplatek na školení rozhodčích ledního hokeje - Vyškov; 12. - 15.10.2023</h2>
        <div class="im_address">
          <span>Prosím proveďte doplatek a vyplňte pro nás potřebné informace v následujících krocích:</span>
        </div>
      </div>
      <div class="form-wrapper">
          <form id="kontaktForm">
            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 1:</b> Vyplnění potřebných informací</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
<!--              <v-col class="" lg="6" md="6" sm="6" cols="12">-->
              <v-col cols="12">
                  <label>
                    <input name="jmeno" id="jmeno" type="text" v-model="formData.jmeno" placeholder="Jméno a příjmení *"/>
                  </label>
              </v-col>
<!--              <v-col lg="6" md="6" sm="6" cols="12">-->
<!--                <label>-->
<!--                  <v-select outlined id="dres" name="dres" v-model="formData.dres" :items="['S (46)', 'M (48)', 'L (50)', 'XL (52)', 'XXL (54)']" placeholder="Preferovaná velikost rozhodcovského dresu *"></v-select>-->
<!--                </label>-->
<!--              </v-col>-->
            </v-row>

<!--            <v-row class="mb&#45;&#45;30">-->
<!--              <v-col cols="12">-->
<!--                <label>-->
<!--                  <v-select outlined id="ubytovaniOd" name="ubytovaniOd" v-model="formData.ubytovaniOd" :items="['XXXXXX XX.XX.2023', 'XXXX XX.XX.2023']" placeholder="Ubytování využiji od *"></v-select>-->
<!--                </label>-->
<!--              </v-col>-->
<!--            </v-row>-->

            <v-divider class="d-none d-lg-flex" dark></v-divider>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 2:</b> Proveďte doplatek</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;">Doplatek na školení rozhodčích ve výši 2.900,-Kč uhraďte bankovním převodem na účet číslo <b>8577469002/5500</b> pod variabilním symbolem 20XXXXXX22, kde XXXXXX je 6 čísel z rodného čísla před lomítkem.</p>
              </v-col>
            </v-row>

            <v-divider class="d-none d-lg-flex" dark></v-divider>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <p style="color: #bfbdbd;"><b>Krok 3:</b> Prosím připojte (nahrajte) jakékoli potvrzení o provedené platbě (například: .pdf potvrzení, foto, printscreen - snímek z mobilu apod.)</p>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" cols="12">
                  <v-file-input label="Vyberte soubor kliknutím" dark v-model="formData.soubor"></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                <v-btn :loading="loading" :color="!success ? 'primary' : 'secondary'" @click.prevent="onSubmit()" :style="success ? 'pointer-events: none; background: #FFBE00; border-color: #FFBE00' : ''">{{ success ? 'Informace odeslány' : 'Odeslat informace' }}</v-btn>
                <!-- <button class="btn-default" @click.prevent="onSubmit()" loading v-html="success ? 'Odesláno' : 'Závazně se zaregistrovat'" :style="success ? 'pointer-events: none; background: #FFBE00; border-color: #FFBE00' : ''"></button> -->
              </v-col>
            </v-row>
          </form>

          <p v-if="error" :style="`color: ${success ? 'green' : 'red'};`">{{error}}</p>
      </div>
    </v-col>

  </v-row>
</template>

<script>
  import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
  import {addDoc, collection} from "firebase/firestore";
  import {db} from "@/firebase";

  export default {
    data() {
      return {
        formData: {
          jmeno: "",
          soubor: null
        },
        loading: false,
        error: null,
        success: false,
      };
    },
    methods: {
      async onSubmit() {
        if (this.formData.jmeno && this.formData.soubor) {
          this.loading = true

          const storage = getStorage()
          const storageRef = ref(storage, `vyskov2023/doplatek/${this.formData.jmeno} - ${new Date().toLocaleString().replaceAll('/', '.')} - ${this.formData.soubor.name.replaceAll('/', '_')}`)
          uploadBytes(storageRef, this.formData.soubor)
            .then((snapshot) => {
              getDownloadURL(snapshot.ref)
                .then(async (url) => {
                  const docRef = collection(db, 'Doplatky2023')
                  await addDoc(docRef, {
                    fullName: this.formData.jmeno,
                    date: new Date(),
                    file: url,
                  })

                  this.success = true
                  this.loading = false
                  this.error = 'Potvrzujeme přijetí údajů. Děkujeme!'
                })
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          this.error = "Vyplňte prosím všechna povinná pole";
        }
      }
    }
  };
</script>

<style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
  .inpur-error{
    color: white;
  }
  .link-kontakt{
    color: white!important;
  }
  .link-kontakt:hover{
    color: var(--color-secondary)!important;
  }
  .v-input__slot {
    padding: 5px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: .4rem!important;
  }
  .v-select__selection--comma, .v-select__selection {
    color: rgba(198, 201, 216, 0.75);
    padding: 0 10px;
  }
  #kraj, #forma, #dres, #ubytovaniOd {
    padding: 0 7px;
  }
  .v-select .v-input__slot {
    min-height: 46px!important;
  }
  .v-select__slot .v-input__append-inner{
    margin-top: 13px!important;
  }
  input#kraj, input#forma, input#dres, input#ubytovaniOd{
    border: none;
  }
  input#kraj::placeholder, input#forma::placeholder, input#dres::placeholder, input#ubytovaniOd::placeholder{
    color: #757575;
  }
  .v-autocomplete{
    padding: 0!important;
  }
  .v-icon.notranslate.mdi.mdi-menu-down.theme--light{
    color: #D3002C!important;
  }

  .v-input__slot, .mdi-paperclip {
    border: none!important;
  }
</style>
