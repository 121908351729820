<template>
  <div>
    <!-- Start 404 Page  -->
    <div class="error-page-inner bg_color--4" style="height: 100vh;">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title" style="color: #D3002C;">404</h1>
              <h3 class="sub-title">Stránka nenalezena</h3>
              <!-- <span>The page you were looking for could not be found.</span> -->
              <!-- <div class="error-button"> -->
                <!-- <router-link class="btn-default" to="/">Back To Homepage</router-link> -->
              <!-- </div> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        logo: require("../assets/images/logo/logo.png"),
      };
    },
  };
</script>
