<template>
  <v-row>
    <v-col cols="12">
      <!-- Start Tab Content  -->
      <div class="rn-testimonial-content">
        <v-row align="center">
          <v-col md="6" sm="12" cols="12" class="tesimonail-conent-inner">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in testimonialContent" :key="item.id">
                <v-card flat v-for="(item, i) in item.content" :key="i">
                  <v-card-text>
                    <div class="inner">
                      <p>
                        {{ item.description }}
                      </p>
                    </div>
                    <div class="author-info">
                      <h6>
                        <b><span>{{ item.name }}</span> -
                        {{ item.designation }}</b>
                      </h6>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <!-- End .v-tabs-items -->
          </v-col>
          <v-col md="6" sm="12" cols="12" class="mt_md--40 mt_sm--40">
            <v-tabs v-model="tab" centered hide-slider center-active>
              <v-tab v-for="item in tabItems" :key="item.id" :ripple="false">
                <div class="thumb">
                  <img :src="item.src" alt="testimonial image" />
                </div>
              </v-tab>
            </v-tabs>
            <!-- End .v-tabs -->
          </v-col>
        </v-row>
      </div>
      <!-- End .rn-testimonial-content -->
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        tab: null,
        testimonialContent: [
          {
            id: 1,
            content: [
              {
                name: "Antonín Jeřábek",
                description:
                  "Řídit utkání na vrcholných akcích a v KHL pro mě byla vždy čest a zároveň odpovědnost. Hokej mi dal jedinečnou šanci zažít atmosféru olympijských her a mistrovství světa, poznat nové lidi a procestovat celý svět.",
                designation: " Rozhodčí extraligy se zkušenostmi z olympiád a KHL",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                name: "Pavel Mikšík",
                description:
                  "S pískáním jsem začal, protože jsem chtěl zůstat u hokeje. Motivovala mě také finanční stránka. Nevěděl jsem, jestli to v tak mladém věku dokáži. S pomocí zkušenějších kolegů jsem to zkusil a hned mě to začalo bavit.",
                designation: " rozhodčí 2.ligy",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                name: "Kamila Smetková",
                description:
                  "Při zápasu zažívám různé emoce. Převažují ty šťastnější. Když rozhodčí udělá správné rozhodnutí, je to stejný pocit, jako pro hráče, když dá gól. Zápasy jsou plné emocí, ale i velké zodpovědnosti. A to se mi líbí.",
                designation: " rozhodčí s nejvyšší licencí Mezinárodní federace ledního hokeje",
              },
            ],
          },
        ],

        tabItems: [
          {
            id: 1,
            src: require("../../assets/images/hokej/reference/ref4.png"),
          },
          {
            id: 2,
            src: require("../../assets/images/hokej/reference/ref2.png"),
          },
          {
            id: 3,
            src: require("../../assets/images/hokej/reference/ref3.png"),
          }
        ],
      };
    },
  };
</script>
