<template>
  <v-row class="row--20 align-center skoleni">
    <v-col md="6" sm="12" cols="12" class="skoleni-video">
      <v-row justify="center">
        <video autoplay loop muted src="@/assets/hokej.mp4"></video>
      </v-row>
      <!-- <v-img src="@/assets/images/hokej/program2.png" contain max-height="350" class="rounded" /> -->
    </v-col>
    <v-col md="6" sm="12" cols="12">
      <div class="about-inner inner">
        <div class="section-title">
          <h2 class="heading-title">Program školení</h2>
          <p class="description">
            Absolvováním základního školení pro rozhodčí ledního hokeje získáš licenci rozhodčího ledního hokeje (Licenci pro všechny žákovské soutěže, nebo licenci pro pomocné rozhodčí - box).   
          </p>
          <p>
            Školení je vícedenní a během něho se mimo jiné seznámíš formou přednášek s pravidly ledního hokeje, přímo na ledě se naučíš základy vhazování, signalizace a pohybu rozhodčího. Zařazeno je většinou i sledování hokejového zápasu a činnosti rozhodčích s následným rozborem. A nejen to. Poznáš mnoho nových kamarádů – tvých budoucích parťáků na ledě. Program je nabitý, ale určitě tě bude bavit! Základní školení nových rozhodčích je zakončeno testem z teorie a také testy z bruslení. Není se čeho bát, na vše tě během školení dobře připravíme. Školení pro pomocné rozhodčí - box je jednodenní a je zakončeno pouze testem z teorie. 
          </p>

        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    data(){
      return{
        dialog: false,
        portfolioContent: [
          {
            src: require("@/assets/images/hokej/program2.png"),
            title: "Ukázka z programu",
            description: "Ukázka z programu základního školení rozhodčích III. Třídy"
          }
        ]
      }
    }
  };
</script>

<style lang="scss" scoped>
.v-card__text{
  padding: 0!important;
}

.skoleni{
  flex-direction: initial;
}

.skoleni-video {
  margin-top: 0;
}

@media only screen and (max-width : 991.5px) {
  .skoleni{
    flex-direction: column-reverse;
  }
  .skoleni-video {
    margin-top: 48px;
  }
}
</style>
