<template>
  <v-row align="start" class="row--35">
    <!-- <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1"> -->
    <v-col lg="6" md="12" sm="12" cols="12">
      <div class="text-left section-title mb--50">
        <h2 class="heading-title">Zanechej nám kontakt!</h2>
        <div class="im_address">
          <span>Stačí nezávazně vyplnit základní údaje a my se ti ozveme s bližšími informacemi.</span>
        </div>
      </div>

      <div class="form-wrapper">
	      <!-- Begin Mailchimp Signup Form -->
<!--	      <div id="mc_embed_signup">-->
<!--		      <form action="https://piskejhokej.us18.list-manage.com/subscribe/post?u=f8df4fa29e8117fca53044bda&amp;id=e915670913&amp;f_id=00d171e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">-->
<!--			      <div id="mc_embed_signup_scroll">-->

<!--				      <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>-->
<!--				      <div class="mc-field-group">-->
<!--					      <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>-->
<!--					      </label>-->
<!--					      <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" required>-->
<!--					      <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>-->
<!--				      </div>-->
<!--				      <div class="mc-field-group">-->
<!--					      <label for="mce-FNAME">Jméno  <span class="asterisk">*</span>-->
<!--					      </label>-->
<!--					      <input type="text" value="" name="FNAME" class="required" id="mce-FNAME" required>-->
<!--					      <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>-->
<!--				      </div>-->
<!--				      <div class="mc-field-group">-->
<!--					      <label for="mce-LNAME">Příjmení  <span class="asterisk">*</span>-->
<!--					      </label>-->
<!--					      <input type="text" value="" name="LNAME" class="required" id="mce-LNAME" required>-->
<!--					      <span id="mce-LNAME-HELPERTEXT" class="helper_text"></span>-->
<!--				      </div>-->
<!--				      <div class="mc-field-group">-->
<!--					      <label for="mce-MMERGE8">Město (trvalé bydliště) </label>-->
<!--					      <input type="text" value="" name="MMERGE8" class="" id="mce-MMERGE8">-->
<!--					      <span id="mce-MMERGE8-HELPERTEXT" class="helper_text"></span>-->
<!--				      </div>-->
<!--				      <div class="mc-field-group size1of2">-->
<!--					      <label for="mce-PHONE">Telefonní číslo  <span class="asterisk">*</span>-->
<!--					      </label>-->
<!--					      <input type="text" name="PHONE" class="required" value="" id="mce-PHONE" required>-->
<!--					      <span id="mce-PHONE-HELPERTEXT" class="helper_text"></span>-->

<!--				      </div>-->
<!--				      <div class="mc-field-group">-->
<!--					      <label for="mce-MMERGE6">Preferovaný kraj školení  <span class="asterisk">*</span>-->
<!--					      </label>-->
<!--					      <select name="MMERGE6" class="required" id="mce-MMERGE6" required>-->
<!--						      <option value=""></option>-->
<!--						      <option value="Hlavní město Praha">Hlavní město Praha</option>-->
<!--						      <option value="Středočeský kraj">Středočeský kraj</option>-->
<!--						      <option value="Jihočeský kraj">Jihočeský kraj</option>-->
<!--						      <option value="Plzeňský kraj">Plzeňský kraj</option>-->
<!--						      <option value="Karlovarský kraj">Karlovarský kraj</option>-->
<!--						      <option value="Ústecký kraj">Ústecký kraj</option>-->
<!--						      <option value="Liberecký kraj">Liberecký kraj</option>-->
<!--						      <option value="Královehradecký kraj">Královehradecký kraj</option>-->
<!--						      <option value="Pardubický kraj">Pardubický kraj</option>-->
<!--						      <option value="Kraj Vysočina">Kraj Vysočina</option>-->
<!--						      <option value="Jihomoravský kraj">Jihomoravský kraj</option>-->
<!--						      <option value="Zlínský kraj">Zlínský kraj</option>-->
<!--						      <option value="Olomoucký kraj">Olomoucký kraj</option>-->
<!--						      <option value="Moravskoslezský kraj">Moravskoslezský kraj</option>-->
<!--					      </select>-->
<!--					      <span id="mce-MMERGE6-HELPERTEXT" class="helper_text"></span>-->
<!--				      </div>-->
<!--				      <div class="mc-field-group">-->
<!--					      <label for="mce-MMERGE7">Forma školení  <span class="asterisk">*</span>-->
<!--					      </label>-->
<!--					      <select name="MMERGE7" class="required" id="mce-MMERGE7" required>-->
<!--						      <option value=""></option>-->
<!--						      <option value="Rozhodčí na led">Rozhodčí na led</option>-->
<!--						      <option value="Pomocný rozhodčí - box">Pomocný rozhodčí - box</option>-->

<!--					      </select>-->
<!--					      <span id="mce-MMERGE7-HELPERTEXT" class="helper_text"></span>-->
<!--				      </div>-->
<!--				      <div id="mce-responses" class="clear foot">-->
<!--					      <div class="response" id="mce-error-response" style="display:none"></div>-->
<!--					      <div class="response" id="mce-success-response" style="display:none"></div>-->
<!--				      </div>    &lt;!&ndash; real people should not fill this in and expect good things - do not remove this or risk form bot signups&ndash;&gt;-->

<!--			      </div>-->
<!--		      </form>-->
<!--	      </div>-->
	      <!--End mc_embed_signup-->

          <form id="kontaktForm" target="_self" method="post" action="https://piskejhokej.us18.list-manage.com/subscribe/post?u=f8df4fa29e8117fca53044bda&amp;id=e915670913&amp;f_id=00d171e7f0">
            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="FNAME" id="mce-FNAME" type="text" v-model="formData.jmeno" placeholder="Jméno a příjmení *"/>
                  </label>
              </v-col>
              <v-col lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="EMAIL" id="mce-EMAIL" type="email" v-model="formData.email" placeholder="E-mail *"/>
                  </label>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="MMERGE8" id="mce-MMERGE8" type="text" v-model="formData.mesto" placeholder="Město (Trvalé bydliště) *"/>
                  </label>
              </v-col>
              <v-col lg="6" md="6" sm="6" cols="12">
                  <label>
                    <input name="PHONE" id="mce-PHONE" type="text" v-model="formData.tel" placeholder="Telefon *"/>
                  </label>
              </v-col>
            </v-row>

            <v-row class="mb--30">
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
                  <label>
                    <v-select outlined :items="kraje" id="mce-MMERGE6" name="MMERGE6" item-value="kraj" return-object v-model="vybranyTermin" placeholder="Preferovaný kraj školení *">
                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{item}}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div class="v-select__selection v-select__selection--comma">{{item}}</div>
                      </template>
                    </v-select>
                  </label>
              </v-col>
              <v-col lg="6" md="6" sm="6" cols="12">
                <label>
                    <v-select outlined id="mce-MMERGE7" name="MMERGE7" v-model="formData.forma" :items="['Rozhodčí na led', 'Pomocný rozhodčí - box']" placeholder="Forma školení *"></v-select>
                  <!-- <v-autocomplete no-data-text="Hledaná forma neexistuje" v-model="formData.forma" name="forma" id="forma" placeholder="Forma školení *" :items="[{text: 'Rozhodčí na led', value: 'Rozhodčí na led'}, {text: 'Pomocný rozhodčí - box', value: 'Pomocný rozhodčí - box'}]"></v-autocomplete> -->
                </label>
              </v-col>

              <!-- <pre class="text-white">{{formData}}</pre> -->
            </v-row>

            <v-row class="align-center">
              <v-col class="mb--30 mb-sm-0" lg="6" md="6" sm="6" cols="12">
	              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_f8df4fa29e8117fca53044bda_e915670913" tabindex="-1" value=""></div>
                <button class="btn-default" @click.prevent="onSubmitGeneric()" v-html="success ? 'Odesláno' : 'Odeslat'" :style="success ? 'pointer-events: none; background: #FFBE00; border-color: #FFBE00' : ''"></button>
              </v-col>
              <v-col class="mb--30 mb-sm-0 text-right" lg="6" md="6" sm="6" cols="12">
                <div class="optionalParent">
                  <div class="clear foot">
                    <p class="brandingLogo"><a href="http://eepurl.com/inWr2Q" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </form>


          <p v-if="error" :style="`color: ${success ? 'green' : 'red'};`">{{error}}</p>
      </div>
    </v-col>

    <v-divider class="d-none d-lg-flex" vertical inset dark></v-divider>

    <!-- <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2"> -->
    <v-col lg="6" md="12" sm="12" cols="12">
      <div class="text-left section-title mt--50 mt-lg-0 mb--50">
        <h2 class="heading-title">Kontakt</h2>
        <div class="im_address">
          <span>Kontaktní informace ohledně náboru nových hokejových rozhodčích.</span>
        </div>
        <ul class="mt--25">
          <li class="mb--15" style="color: white;" v-for="kontakt in kontakty">
            <div class="font-weight-bold" style="color: white;">{{ kontakt.kraj }}</div>
            <div style="color: #bfbdbd;">
              {{ kontakt.jmeno }},
              <a class="link-kontakt2" :href="`tel:+420${kontakt.tel.replaceAll(' ', '')}`">{{ kontakt.tel }}</a>,
              <a class="link-kontakt2" :href="`mailto:${kontakt.email}`">{{ kontakt.email }}</a>
            </div>
          </li>
<!--          <span class="mb&#45;&#45;25">-->
<!--            Martin Maťašovský<br>-->
<!--            Centrální koordinátor projektu-->
<!--          </span><br>-->
<!--          <img class="mb&#45;&#45;25" style="max-width: 33%" src="@/assets/images/hokej/logo-hokej.png" /><br>-->
<!--          <a class="link-kontakt" href="mailto:Matasovsky@czehockey.cz">Matasovsky@czehockey.cz</a><br>-->
<!--          <a class="link-kontakt mb&#45;&#45;25" href="tel:+420720122756">+420 720 122 756</a><br><br>-->
<!--          <span class="mb&#45;&#45;25">-->
<!--            Český svaz ledního hokeje z.s.<br>-->
<!--            Českomoravská 2420/15, Libeň<br>-->
<!--            190 00 Praha 9-->
<!--          </span>-->
        </ul>
      </div>
    </v-col>

  </v-row>
</template>

<script>
import { db } from '../../firebase'
import { collection, addDoc } from 'firebase/firestore'
  export default {
    data() {
      return {
        kontakty: [
          { kraj: 'Jihočeský kraj', jmeno: 'Milan Jindra', tel: '602 417 311', email: 'jindra@davidservis.cz' },
          { kraj: 'Jihomoravský kraj, Kraj Vysočina, Zlínský kraj', jmeno: 'Martin Maťašovský', tel: '720 122 756', email: 'matasovsky@czehockey.cz' },
          { kraj: 'Královéhradecký kraj, Pardubický kraj', jmeno: 'Jiří Stolín', tel: '603 755 946', email: 'stolinda@centrum.cz' },
          { kraj: 'Karlovarský kraj', jmeno: 'Michal Kostourek', tel: '777 094 567', email: 'KostaMichal@seznam.cz' },
          { kraj: 'Liberecký kraj', jmeno: 'Kamil Zavřel', tel: '777 775 032', email: 'krliberec@gmail.com' },
          { kraj: 'Moravskoslezský kraj', jmeno: 'Marek Hlavatý', tel: '775 999 901', email: 'hlavaty.marek@volny.cz' },
          { kraj: 'Olomoucký kraj', jmeno: 'Petr Sedlák', tel: '608 193 051', email: 'selen201@seznam.cz' },
          { kraj: 'Plzeňský kraj', jmeno: 'Gabriela Malá', tel: '733 680 709', email: 'gabik.dvor@gmail.com' },
          { kraj: 'Praha', jmeno: 'Arnošt Nečas', tel: '724 002 966', email: 'arnost.necas@grafie.cz' },
          { kraj: 'Středočeský kraj', jmeno: 'Petr Macháč', tel: '774 131 348', email: 'machac@stredoceskyhokej.cz ' },
          { kraj: 'Ústecký kraj', jmeno: 'Pavel Beneš', tel: '606 775 980', email: 'benesp@email.cz' },
        ],
        formData: {
          jmeno: "",
          tel: "",
          email: "",
          mesto: "",
          forma: "",
          kraj: ""
        },
        vybranyTermin: null,
        error: null,
        success: false,
        terminy: [
          { termin: '19. – 21.07.2022', misto: 'Přerov', kraj: 'Olomouckej kraj' },
          { termin: '26. – 28.08.2022', misto: 'Olomouc', kraj: 'Olomoucký kraj' },
          { termin: '26. – 28.08.2022', misto: 'Plzeň', kraj: 'Plzeňský kraj' },
          { termin: '27. – 28.08.2022', misto: 'Karlovy Vary', kraj: 'Karlovarský kraj' },
          { termin: '2. – 04.09.2022', misto: 'Ústí nad Labem', kraj: 'Ústecký kraj' },
          { termin: '2. – 04.09.2022', misto: 'Milevsko', kraj: 'Jihočeský kraj' },
          { termin: '9. – 11.09.2022', misto: 'Hronov', kraj: 'Královehradecký kraj' },
          { termin: '9. – 11.09.2022', misto: 'Hronov', kraj: 'Pardubický kraj' },
          { termin: '9. – 11.09.2022', misto: 'Praha', kraj: 'Hlavní město Praha' },
          { termin: '16. – 17.09.2022', misto: 'Ostrava, Poruba', kraj: 'Moravskoslezský kraj' },
          { termin: '24. – 25.09.2022', misto: 'Liberec', kraj: 'Liberecký kraj' },
          // { termin: '13. – 16.10.2022', misto: 'Vyškov', kraj: 'Jihomoravský kraj' },
          // { termin: '13. – 16.10.2022', misto: 'Vyškov', kraj: 'Kraj Vysočina' },
          // { termin: '13. – 16.10.2022', misto: 'Vyškov', kraj: 'Zlínský kraj' },
          { termin: '21. – 23.10.2022', misto: 'Slaný', kraj: 'Středočeský kraj' }
        ],
        submitURL: {
          "Olomoucký kraj": "https://marketing.construsoft.com/acton/eform/36418/01195265-866f-4f43-81f8-be640f7db060/d-ext-0001",
          "Plzeňský kraj": "https://marketing.construsoft.com/acton/eform/36418/333b1695-13a4-4aa0-bc91-3e42e76169f6/d-ext-0001",
          "Karlovarský kraj": "https://marketing.construsoft.com/acton/eform/36418/489206ed-4550-4d9e-9fce-74674ab83cf0/d-ext-0001",
          "Ústecký kraj": "https://marketing.construsoft.com/acton/eform/36418/7e025e0b-f7c5-4cbf-9048-cca6b1b4a0fe/d-ext-0001",
          "Jihočeský kraj": "https://marketing.construsoft.com/acton/eform/36418/9653583b-a07f-4623-bafa-3b37c4d65d27/d-ext-0001",
          "Královehradecký kraj": "https://marketing.construsoft.com/acton/eform/36418/6bd044ae-121c-4086-bf3a-d3168055c553/d-ext-0001",
          "Pardubický kraj": "https://marketing.construsoft.com/acton/eform/36418/6bd044ae-121c-4086-bf3a-d3168055c553/d-ext-0001",
          "Hlavní město Praha": "https://marketing.construsoft.com/acton/eform/36418/974dcdba-8244-4c01-80b8-f93cb832d1fc/d-ext-0001",
          "Moravskoslezský kraj": "https://marketing.construsoft.com/acton/eform/36418/02cfdd0e-ffdc-4c00-9748-e1e64cf74530/d-ext-0001",
          "Liberecký kraj": "https://marketing.construsoft.com/acton/eform/36418/d9b66764-dc08-4339-80f7-991ec0a96671/d-ext-0001",
          "Jihomoravský kraj": "https://marketing.construsoft.com/acton/eform/36418/e49f51cf-f021-4935-9b81-eb0292ec4156/d-ext-0001",
          "Kraj Vysočina": "https://marketing.construsoft.com/acton/eform/36418/e49f51cf-f021-4935-9b81-eb0292ec4156/d-ext-0001",
          "Zlínský kraj": "https://marketing.construsoft.com/acton/eform/36418/e49f51cf-f021-4935-9b81-eb0292ec4156/d-ext-0001",
          "Středočeský kraj": "https://marketing.construsoft.com/acton/eform/36418/bdff5304-3cd5-4245-8016-999998b4d7d7/d-ext-0001"
        },
        kraje: ['Hlavní město Praha', 'Středočeský kraj', 'Jihočeský kraj', 'Plzeňský kraj', 'Karlovarský kraj', 'Ústecký kraj', 'Liberecký kraj', 'Královéhradecký kraj', 'Pardubický kraj', 'Kraj Vysočina', 'Jihomoravský kraj', 'Zlínský kraj', 'Olomoucký kraj', 'Moravskoslezský kraj']
      };
    },
    mounted () {
      const temp = []
      for (let i = 0; i < this.terminy.length; i++) {
        const termin = this.terminy[i];
        const datum = termin.termin.split(' – ')[1].replaceAll('.', '/');
        const datum2 = datum.substr(6, 4)+"-"+datum.substr(3, 2)+"-"+datum.substr(0, 2)
        const datum3 = new Date(datum2)
        const datumNow = new Date()

        if (datum3 > datumNow) {
          temp.push(termin)
        }
      }
      this.terminy = temp
    },
    methods: {
      async onSubmitGeneric() {
        // live https://marketing.construsoft.com/acton/eform/36418/b801a318-6f02-4572-9954-e0c4ebb61476/d-ext-0001
        // dev https://marketing.construsoft.com/acton/eform/36418/a2083420-a367-4454-812c-d26b616250f8/d-ext-0001
        if(this.formData.jmeno && this.formData.email && this.formData.mesto && this.formData.tel && this.formData.forma && this.vybranyTermin && !this.success) {
          this.formData.kraj = this.vybranyTermin

		        const name = this.formData.jmeno.split(' ')
          const mailchimpData = {
						"FNAME": name[0],
		        "LNAME": name[1],
			  		"EMAIL": this.formData.email,
						"PHONE": this.formData.tel,
						"MMERGE8": this.formData.mesto,
						"MMERGE6": this.vybranyTermin,
						"MMERGE7": this.formData.forma,
          }

          this.post('https://piskejhokej.us18.list-manage.com/subscribe/post?u=f8df4fa29e8117fca53044bda&amp;id=e915670913&amp;f_id=00d171e7f0', mailchimpData)

          const docRef = collection(db, 'customers')
          await addDoc(docRef, {
            address: this.formData.mesto,
            email: this.formData.email,
            form: this.formData.forma,
            fullName: this.formData.jmeno,
            phone: this.formData.tel,
            region: this.vybranyTermin,
            date: new Date()
          })

          this.error = "Děkujeme. Brzy se vám ozveme!"
          this.success = true
        }else{
          this.error = "Vyplňte prosím všechna povinná pole."
          console.log(this.formData)
          console.log(Object.fromEntries(new FormData(document.querySelector('#kontaktForm'))))
        }
      },
      onSubmit() {
        //https://marketing.construsoft.com/acton/eform/36418/b801a318-6f02-4572-9954-e0c4ebb61476/d-ext-0001
          // https://piskejhokej.us18.list-manage.com/subscribe/post?u=f8df4fa29e8117fca53044bda&amp;id=e915670913&amp;f_id=00d171e7f0
        if(this.formData.jmeno && this.formData.email && this.formData.mesto && this.formData.tel && this.formData.forma && this.vybranyTermin && !this.success) {
          this.formData.kraj = `${this.vybranyTermin.kraj} (${this.vybranyTermin.misto}, ${this.vybranyTermin.termin})`;
          this.post(this.submitURL[this.vybranyTermin.kraj], this.formData)

          this.error = "Děkujeme. Brzy se vám ozveme!"
          this.success = true
        }else{
          this.error = "Vyplňte prosím všechna povinná pole."
          console.log(this.formData)
          console.log(Object.fromEntries(new FormData(document.querySelector('#kontaktForm'))))
        }
      },

      post(path, params, method='post') {
        const form = document.createElement('form')
        form.method = method
        form.action = path
        form.target = 'iframeForm'

        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input')
            hiddenField.type = 'hidden'
            hiddenField.name = key
            hiddenField.value = params[key]

            form.appendChild(hiddenField)
          }
        }

        document.body.appendChild(form)
        console.log(Object.fromEntries(new FormData(form)))
        form.submit()
      }
    }
  };
</script>

<style lang="scss">
  .form-wrapper label input,
  .form-wrapper label textarea {
    margin-bottom: 0;
  }
  .form-wrapper label {
    margin-bottom: 20px;
  }
  .inpur-error{
    color: white;
  }
  .link-kontakt{
    color: white!important;
    font-weight: normal!important;
  }
  .link-kontakt:hover{
    color: var(--color-secondary)!important;
  }
  .link-kontakt2{
    color: #bfbdbd!important;
    font-weight: normal!important;
    text-decoration: underline!important;
  }
  .link-kontakt:hover, .link-kontakt2:hover {
    color: var(--color-secondary)!important;
    text-decoration: none!important;
  }
  .v-input__slot {
    padding: 5px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: .4rem!important;
  }
  div.v-input__slot {
    border: 2px solid rgba(255, 255, 255, 0.2)!important;
  }
  .v-select__selection--comma, .v-select__selection {
    color: rgba(198, 201, 216, 0.75);
    padding: 0 10px;
  }
  #mce-MMERGE6, #mce-MMERGE7 {
    padding: 0 7px;
  }
  .v-select .v-input__slot {
    min-height: 46px!important;
  }
  .v-select__slot .v-input__append-inner{
    margin-top: 13px!important;
  }
  input#mce-MMERGE6, input#mce-MMERGE7{
    border: none;
  }
  input#mce-MMERGE6::placeholder,  input#mce-MMERGE7::placeholder{
    color: #757575;
  }
  .v-autocomplete{
    padding: 0!important;
  }
  .v-icon.notranslate.mdi.mdi-menu-down.theme--light{
    color: #D3002C!important;
  }
</style>
