<template>
  <v-app id="inspire">
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-fab-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        color="white"
        fixed
        width="50"
        height="50"
        bottom
        right
        @click="toTop"
        class="scroll-fab"
      >
        <svg
          stroke="currentColor"
          fill="none"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke-linecap="round"
          stroke-linejoin="round"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polyline points="18 15 12 9 6 15"></polyline>
        </svg>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      fab: false,
    }),
    methods: {
      onScroll(e) {
        if (typeof window === "undefined") return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        this.fab = top > 60;
      },
      toTop() {
        // this.$vuetify.goTo(0);
        // Scroll to top
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
    },
  };
</script>

<style scoped lang="scss">
  .v-btn--fab.v-btn--contained {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  .v-btn--icon.v-size--default .v-icon,
  .v-btn--fab.v-size--default .v-icon {
    height: 30px;
    font-size: 30px;
    width: 30px;
  }
  .v-btn--fab.v-btn--fixed {
    z-index: 99;
  }
  .v-btn--absolute.v-btn--bottom,
  .v-btn--fixed.v-btn--bottom {
    bottom: 60px;
  }

  .v-btn--absolute.v-btn--right,
  .v-btn--fixed.v-btn--right {
    right: 20px;
  }
  .scroll-fab {
    &.white {
      border: none;
    }
    svg {
      font-size: 24px;
    }
  }
  .business-brand-area {
    .row.mt--20 {
      margin-top: 0 !important;
    }
  }
    .rn-countreupup-area {
    &.pt--60 {
      @media only screen and (max-width: 991px) {
        padding-top: 20px;
      }
      @media only screen and (max-width: 767px) {
        padding-top: 8px;
      }
    }
  }
    .flat-fluid-app-bar {
    a.btn-default {
      padding: 13px 30px;
    }
  }
  .creative-portfolio-wrapper.plr--30 {
    @media only screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
    .feather-menu {
    color: #1d1d24;
  }
    .no-spacer-inside-brand {
    .row.mt--20 {
      margin-top: 0 !important;
    }
  }
    @media only screen and (min-width: 1264px) and (max-width: 1903px) {
    .slider-video-bg.slide.slide-style-2 {
      padding-top: 160px;
      padding-bottom: 260px;
      min-height: auto;
    }
  }
    .service-white-wrapper
    .service-main-wrapper
    .service.service__style--2.bg-gray {
    background: #fff;
  }
    .inner-counter-one.pt--60 {
    @media screen and (max-width: 991px) {
      padding-top: 20px !important;
    }
    @media screen and (max-width: 767px) {
      padding-top: 0 !important;
    }
  }
  .inner-counter-two.pt--60 {
    @media screen and (max-width: 991px) {
      padding-top: 20px !important;
    }
    @media screen and (max-width: 767px) {
      .row.mt--30 {
        margin-top: 15px !important;
      }
    }
  }
    .header-area .logo a img {
    max-height: 60px;
  }
  .faq-area {
    strong {
      color: var(--color-primary);
      font-weight: 700;
    }
  }
  @media only screen and (max-width: 767px){
    .home-demo-area.inner-page-demo{
      .single-demo{
        padding: 0;
      }
      .wrapper{
        padding: 0 20px !important;
      }
    }
    .prv-feature {
      .plr_sm--30 {
          padding: 0 20px !important;
      }
    }
  }
    .theme--light.about-expension-panels .v-expansion-panel-header::after {
    height: 1px;
    width: 100%;
  }
  .theme--light.about-expension-panels .v-expansion-panel-header {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 0 10px 0;
    font-size: 18px;
  }
  .main-page-preview-gallery {
    .v-tabs {
      margin: 35px auto 30px;
    }
  }
  .v-expansion-panel-content__wrap {
    p {
      a {
        font-weight: 500;
      }
    }
  }
    .slick-slide {
    img {
      display: block;
      width: 100%;
    }
  }
    .demo_slider-area {
    .slide.slide-style-1 .inner {
      padding-top: 70px;
    }
    @media only screen and (max-width: 991px) {
      .slider-fixed--height {
        padding-bottom: 70px;
      }
      .service-white .service__style--1 .content p {
        padding: 0 30px;
      }
    }
  }
  .slider-wrapper {
    .demo_slider-area {
      .service-wrapper {
        margin-bottom: 0;
      }
    }
  }
    @media only screen and (max-width: 767px) {
    .default-header a.btn-default {
      font-size: 14px;
      letter-spacing: 0;
      padding: 7px 16px;
    }
  }
  .demo-page-drawer {
    &.v-navigation-drawer--is-mobile .v-list {
      padding-bottom: 0 !important;
    }
  }
  .preview-header {
    &.v-toolbar:not(.tab-toolbar)
      .v-btn.v-btn.v-size--default:not(.btn-default) {
      &:hover {
        color: var(--color-primary);
      }
    }
  }

  .demo-page-drawer.v-navigation-drawer--is-mobile
    .v-list-item--link:last-child {
    border-bottom: 1px solid var(--color-border);
  }











</style>
