<template>
  <div>
    <v-expansion-panels accordion flat  class="about-expension-panels">
      <v-col md="6" sm="12" cols="12">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Fair-play</v-expansion-panel-header>
          <v-expansion-panel-content>Nestrannost a objektivita je potřeba za každé situace. Emoce musí stranou! Vždy, když chceš písknout do píšťalky, se musíš opřít o pravidla ledního hokeje a zdravý rozum. Jde to! Nadhled a odpovědnost tě posune i v osobním životě.</v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Noví kamarádi</v-expansion-panel-header>
          <v-expansion-panel-content>Díky pískání poznáš celou řadu nových tváří – ať už na ledě nebo v okolí hokejového dění. Rodina hokejových rozhodčích se na tebe těší! Každoročně se můžeš zúčastnit různých teambuildingových akcí, které se pro rozhodčí konají.</v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Nové zážitky</v-expansion-panel-header>
          <v-expansion-panel-content>Cestování na zápasy, předzápasová příprava i samotné řízení utkání přináší celou řadu nečekaných situací. Těm příjemným se i zasmějeme a dokážeme se k nim neustále vracet. Stanou se nesmazatelnými zážitky, na které se nezapomíná.</v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Možnost zůstat u hokeje i po konci hráčské kariéry</v-expansion-panel-header>
          <v-expansion-panel-content>Pokud ses rozhodl ukončit hráčskou kariéru, je právě pískání tou ideální volbou, abys u tohoto sportu mohl nějakým způsobem zůstat, potkávat se s hráči, trenéry a být nedílnou součástí hokejových utkání.</v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
      <v-col md="6" sm="12" cols="12">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Poznání hokeje z jiné stránky</v-expansion-panel-header>
          <v-expansion-panel-content>Jako rozhodčí se ocitneš na jiné straně hokejového dění. Nebudeš fandit na tribuně, ale ani střílet góly přímo na ledě. Budeš odpovídat za řízení celého hokejového zápasu! V každý moment se musíš umět správně rozhodnout a ostatní účastníci zápasu na tebe budou vždy spoléhat.</v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Získáš možnost pískat nejvyšší soutěže</v-expansion-panel-header>
          <v-expansion-panel-content>Vytvoříme ti dobré zázemí. Budeš mít možnost se na nás vždy obrátit. To ostatní bude záležet ale na tobě. Jen ty se můžeš svou pílí a výkony probojovat na nominační listiny těch největších soutěží a zažít atmosféru přeplněných stadiónů. </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>Zajímavý přivýdělek</v-expansion-panel-header>
          <v-expansion-panel-content>Za svoji dobře odvedenou práci si zasloužíš <v-dialog v-model="dialog" width="768">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="odmeny">odpovídající finanční odměnu.</span>
            </template>
            <v-card>
              <v-card-text>
                <!-- <img class="w-100" src="../../assets/images/hokej/odmeny.png" alt="Odměny" /> -->
                <v-img src="@/assets/images/hokej/odmeny.png" max-width="768"></v-img>
              </v-card-text>
            </v-card>
          </v-dialog> To se týká i situací, kdy budeš pomáhat jako rozhodčí „mimo“ led v boxu pomocných rozhodčích.
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>A mnoho dalšího…</v-expansion-panel-header>
          <v-expansion-panel-content>Vstup zdarma na vybrané zápasy ledního hokeje, ve vyšších soutěžích bezplatné materiální vybavení a mnoho dalšího. Být rozhodčím je prostě srdcovka, která tě chytne a bude bavit! </v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
    </v-expansion-panels>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        panel: 2,
        dialog: false
      };
    },
  };
</script>

<style scoped>
.v-expansion-panel::before{
  box-shadow: none!important;
}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after{
  border: none;
}
.v-card__text{
  padding: 0!important;
}
.odmeny{
  font-weight: bold;
  color: white;
}
</style>