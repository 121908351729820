// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import { getFirestore } from "firebase/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDerQYtAiajd9BHcwM5elrhhQ2Z58s--bI",
  authDomain: "piskejhokej.firebaseapp.com",
  projectId: "piskejhokej",
  storageBucket: "piskejhokej.appspot.com",
  messagingSenderId: "1097988950141",
  appId: "1:1097988950141:web:ab1e11b493764a3ddd0914"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
export const db = getFirestore(app)