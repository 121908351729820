<template>
  <v-row class="row--35 align-center">
    <v-col cols="12">
      <div class="about-inner inner">
        <div class="section-title text-center">
          <h2 class="heading-title">Termíny školení</h2>
          <div v-if="terminy" class="heading-title">
            <v-simple-table dark v-if="terminy.length > 0">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Datum
                  </th>
                  <th class="text-left">
                    Kraj
                  </th>
                  <th class="text-left">
                    Město
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(termin, index) in terminy"
                    :key="index"
                >
                  <td class="text-left">{{ termin.datum }}</td>
                  <td class="text-left">{{ termin.kraj }}</td>
                  <td class="text-left">{{ termin.mesto }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p v-else>V současné době nemáme naplánované žádné termíny školení.</p>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { db } from '../../firebase'
import { collection, getDocs, query } from 'firebase/firestore'

export default {
  data() {
    return {
      terminy: []
    };
  },
  mounted() {
    console.log('termíny školení mounted')
    const docRef = query(collection(db, 'terminy'))
    getDocs(docRef)
        .then(docs => {
          docs.forEach((doc) => {
            const data = doc.data()
            const t = data.datum.split(' – ')[0] // Temp
            const s = t.split('.') // Split
            const r = `${s[2]}/${s[1]}/${s[0]}` // Reverse

            data.timestamp = new Date(r)
            data.mesto = data.mesto || 'TBA'
            this.terminy.push(data)
          })

          // Sort terminy by timestamp
          this.terminy.sort((a, b) => {
            return a.timestamp - b.timestamp
          })
        })
        .catch(error => {
          console.warn(error, docRef)
        })
  }
};
</script>
